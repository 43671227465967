import React, { useEffect, useState, updateState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import CloseCard from '../../../assets/images/CloseTask.svg';
import { Input, View, Button, Card, InvesteeSidebar, Textbox, Textarea, SelectTag, CustomSelect, ResizableTextarea } from '../../../components';
import SocialMedia from '../../../assets/images/SocialMedia.svg';
import Brochure from '../../../assets/images/Brochure.svg';
import Flyer from '../../../assets/images/Flyer.svg';
import Presentation from '../../../assets/images/Presentation.svg';
import Poster from '../../../assets/images/Poster.svg';
import Filter from '../../../assets/images/Filter.svg';
import FilterDown from '../../../assets/images/FilterDown.svg';
import { FaDivide } from 'react-icons/fa';
var mobile = require('is-mobile');
const Categories = [
    {
        Category: 'Website',
        subCategory: 'Bug Fixes',
        Description: 'There is an issue in the current platform that needs to be resolved',
        Image: null,
        Department: 'Dev'
    },
    {
        Category: 'Website',
        subCategory: 'Feature Request',
        Description: 'An additional feature needs to be added onto an existing platform',
        Image: null,
        Department: 'Dev'
    },
    {
        Category: 'Website',
        subCategory: 'Support Meeting',
        Description: 'A user has been in contact regarding an issue or question that requires a developer to provide an answer for or a solution to',
        Image: null,
        Department: 'Dev'
    },
    {
        Category: 'App',
        subCategory: 'Bug Fixes',
        Description: 'There is an issue in the current platform that needs to be resolved',
        Image: null,
        Department: 'Dev'
    },
    {
        Category: 'App',
        subCategory: 'Feature Request',
        Description: 'An additional feature needs to be added onto an existing platform',
        Image: null,
        Department: 'Dev'
    },
    {
        Category: 'App',
        subCategory: 'Support Meeting',
        Description: 'A user has been in contact regarding an issue or question that requires a developer to provide an answer for or a solution to',
        Image: null,
        Department: 'Dev'
    },
    {
        Category: 'StillGraphics',
        subCategory: 'Social Media',
        Description: 'Tailored still images/designs for specific platforms to enhance brand engagement. Possible platforms include Linkedin, Instagram, Facebook.',
        Image: SocialMedia,
        Department: 'Design'
    },
    {
        Category: 'StillGraphics',
        subCategory: 'Flyer',
        Description: 'A promotional document designed for quick distribution. There is no standard sizing for a flyer. Please provide specific size requirements from the printers you will be using to print.',
        Image: Flyer,
        Department: 'Design'
    },
    {
        Category: 'StillGraphics',
        subCategory: 'Brochure',
        Description: 'A multi-page informational document often used for marketing and advertising, providing a concise overview of a company, product, or service. Generally printers will require brochures to be in page variables of 4 = 4,8,12,16, 20,etc. Pages may also need to be provided indivually and not in spreads. Please clarify with the printers you will be using regarding their printing guidlines for brochures.',
        Image: Brochure,
        Department: 'Design'
    },
    {
        Category: 'StillGraphics',
        subCategory: 'Poster',
        Description: 'A poster is a visually impactful, single-sheet promotional material designed for public display. It conveys concise information using eye-catching graphics and text to attract attention and convey a message.',
        Image: Poster,
        Department: 'Design'
    },
    {
        Category: 'StillGraphics',
        subCategory: 'Presentation/Document',
        Description: 'A presentation is a visual communication tool involving spoken content accompanied by slides or visual aids. It serves to convey information, ideas, or messages in a structured and engaging format. Questions to Consider: does this need to be printed or is it digital? Does it need to be editable i.e in Microsoft Powerpoint, or can it be a PDF? A document is a written or electronic piece of information, providing details, instructions, or data on a particular subject. It serves as a record or reference for communication and documentation purposes. Questions to Consider: does this need to be printed or is it digital? Does it need to be editable i.e in Microsoft Word, or can it be a PDF?',
        Image: Presentation,
        Department: 'Design'
    },
    {
        Category: 'WebDesign',
        subCategory: 'New website design',
        Description: 'An online platform accessible through web browsers, comprising web pages with multimedia content and information. It serves various purposes, including information dissemination, communication, and e-commerce.',
        Image: null,
        Department: 'Design'
    },
    {
        Category: 'WebDesign',
        subCategory: 'New App design',
        Description: 'Short for application, an app is a software program designed for mobile devices or computers to perform specific tasks, provide services, or offer entertainment. It typically offers a user-friendly interface for easy interaction.',
        Image: null,
        Department: 'Design'
    },
    {
        Category: 'WebDesign',
        subCategory: 'Web page updates',
        Description: 'Updates involve refreshing the visual elements of a website, including layout, color schemes, and imagery.',
        Image: null,
        Department: 'Design'
    },
    {
        Category: 'WebDesign',
        subCategory: 'App updates',
        Description: 'Updates involve refreshing the visual elements of a website, including layout, color schemes, and imagery.',
        Image: null,
        Department: 'Design'
    },
    {
        Category: 'Video/Animation',
        subCategory: 'Animation - infographics',
        Description: 'Generally focused around text and numbers, animated infographics use dynamic visuals and motion graphics to convey complex information in a visually engaging and easily digestible format. They combine animation and data visualization to enhance audience understanding and retention.',
        Image: null,
        Department: 'Design'
    },
    {
        Category: 'Video/Animation',
        subCategory: 'Animation - characters',
        Description: `Animation characters and designs refer to visually crafted elements in animated content, encompassing characters' appearances, movements, and overall aesthetics. They play a crucial role in conveying the storyline, enhancing engagement, and creating a distinctive visual identity for the animation.`,
        Image: null,
        Department: 'Design'
    },
    {
        Category: 'Video/Animation',
        subCategory: 'Filming - Podcast',
        Description: 'A podcast/podcast is a digital audio or video program available for streaming or download, typically in a series format. It covers a wide range of topics, featuring discussions, interviews, or storytelling, and is accessible on various platforms for audience consumption.',
        Image: null,
        Department: 'Design'
    },
    {
        Category: 'Video/Animation',
        subCategory: 'Filming - Content',
        Description: 'A visual presentation captured on video, covering various topics such as educational content, entertainment, or promotional material. It leverages visual elements to engage and communicate messages effectively.',
        Image: null,
        Department: 'Design'
    },
    {
        Category: 'Video/Animation',
        subCategory: 'Filming - interview',
        Description: 'Serves as a visual tool for sharing insights, expertise, or corporate messages, often enhancing communication and engagement.',
        Image: null,
        Department: 'Design'
    },
    {
        Category: 'Video/Animation',
        subCategory: 'Filming - Product video',
        Description: 'A product video is a short visual presentation showcasing the features, benefits, and usage of a product. It aims to engage and inform potential customers quickly, often using a combination of visuals, demonstrations, and compelling narration.',
        Image: null,
        Department: 'Design'
    },
    {
        Category: 'Written',
        subCategory: 'Newsletter/Emailer',
        Description: 'A concise means of communication with a targeted audience through email. Please indicate whether you already have a mailer platform, eg, mailchimp, etc.',
        Image: null,
        Department: 'Design'
    },
    {
        Category: 'Written',
        subCategory: 'Blog/ Article',
        Description: 'A dynamic online digital journal on various topics. These can be posted onto social media - linekdin, or websites to healp with SEO.',
        Image: null,
        Department: 'Design'
    },
    {
        Category: 'Photo',
        subCategory: 'Product',
        Description: 'Visual representations of a product, showcasing its features, design, and details through high-quality images',
        Image: null,
        Department: 'Design'
    },
    {
        Category: 'Photo',
        subCategory: 'Lifestyle',
        Description: 'Lifestyle photos are candid or staged images that capture the essence of everyday life, often featuring people engaging in various activities.',
        Image: null,
        Department: 'Design'
    },
    {
        Category: 'Photo',
        subCategory: 'Corporate',
        Description: 'Photos are professional images capturing the personnel within a company. The main form of corporate photos are employee headshots.',
        Image: null,
        Department: 'Design'
    },
    {
        Category: 'Photo',
        subCategory: 'Event',
        Description: 'Event photos capture moments and activities during an event, providing a visual record of the occasion. These images showcase attendees, speakers, and key happenings, serving as both documentation and promotional material for the event.',
        Image: null,
        Department: 'Design'
    }
]
const AlkemiaBriefing = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [TabSelected, setTabSelected] = useState('requested');
    const [AddNewTask, setAddNewTask] = useState(false);
    const [Company, setCompany] = useState({});
    const [SelectedTask, setSelectedTask] = useState({});
    const [isHovered, setIsHovered] = useState(false);
    const [Credits, setCredits] = useState({});
    const [ShowTaskInfo, setShowTaskInfo] = useState(false)
    const [Background, setBackground] = useState('');
    const [TitleFilter, setTitleFilter] = useState('A-Z');
    const [SelectedTaskCreds, setSelectedTaskCreds] = useState(0);
    const [JobRequest, setJobRequest] = useState({
        ActualDueDate: null,
        Title: "",
        Description: "",
        FeedBack: '',
        Department: "",
        Archived: false,
        Status: 'requested',
        Client: store.user.Company,
        ClientEmail: store.user.Email,
        DesignSpec: {
            designElements: "",
            collaterals: "",
            category: "",
            listDeliverables: "",
            DocLink: '',
            Platform: '',
            Sizes: '',
            CopyIfRequired: '',
            Attachments: ''
        },
        ClientName: '',
        DevSpec: {
            Platform: "",
            Type: "",
            Outcomes: "",
            Figma_Available: ""
        },
        ReqDate: new Date(),
        DueDate: ''
    });
    const [Tasks, setTasks] = useState([]);
    useEffect(() => {
        getTasks()
    }, []);
    const getTasks = async () => {
        store.setLoading(true);
        let tasks = await store.getJobByEmail(store.user.Company);
        if (typeof tasks === "string" || !tasks) {
            setTasks([]);
            store.setLoading(false);
            console.log('Error Error')
        }
        let comps = await store.getCompaniesByPerms(store.user.Email);
        if (typeof comps === "string" || !comps) {
            setCompany({});
            store.setLoading(false);
            return;
        }
        if (comps.length === 0) {
            setCompany({});
            store.setLoading(false);
            return;
        }
        let comp = comps.filter(item => item.Name === store.user.Company);
        let creds = comp[0].Credits;

        for (let i = 0; i < tasks.length; i++) {
            let task = tasks[i];
            if (task.Status === 'pending') {
                let updatedAtDate = new Date(task.updatedAt);
                let timeDifference = new Date() - updatedAtDate;
                if (timeDifference >= 24 * 60 * 60 * 1000) {
                    store.setLoading(true)
                    let job = task;
                    job.Status = 'inProgress';
                    store.clientAcceptCreds(job).then((ret) => {
                        if (ret === 'error') {
                            window.alert('There was an error updating this job.');
                            store.setLoading(false);
                        } else {
                            setSelectedTask({});
                            store.setLoading(false)
                        }
                    });
                    forceUpdate();
                } else {
                    store.setLoading(false);
                }
            }
        }
        let date = new Date();
        let currentMonth = date.getMonth();
        let currentYear = date.getFullYear();
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let month = monthNames[date.getMonth()];
        let year = date.getFullYear();
        let formattedDate = month + year;
        let currentMonthCredits = creds.find((credit) => credit.Month === currentMonth && credit.Year === currentYear);
        const designTasks = tasks.filter((item) => {
            return item.Department === 'Design' && !item.Status.startsWith('Declined');
        });
        let devTasks = tasks.filter((item) =>
            item.Department === 'Dev' && !item.Status.startsWith('Declined')
        );
        const salesTasks = tasks.filter((item) => {
            return item.Department === 'Sales' && !item.Status.startsWith('Declined');
        });
        let totDevHrs = 0;
        let totDesignHrs = 0;
        let totSalesHrs = 0;
        devTasks.forEach((item) => {
            let est = item.EstimatedHours;
            let act = item.ActualHours;
            item.TotalCredits = 0;
            let credsUsed;
            let reqDate = new Date(item.ReqDate);
            if (Object.keys(item).includes('MonthlyCredits')) {
                let creds = item.MonthlyCredits;
                if (creds.hasOwnProperty(formattedDate)) {
                    totDevHrs += creds[formattedDate];
                    credsUsed += creds[formattedDate];
                }
            } else if (reqDate.getMonth() === date.getMonth() && reqDate.getFullYear() === date.getFullYear()) {
                if (act === undefined || act === null || act === 0 || !act) {
                    if (est === undefined || est === null || est === 0 || !est) {
                        totDevHrs += 0;
                        credsUsed += 0;
                    } else {
                        totDevHrs += est;
                        credsUsed += est
                    }
                } else {
                    totDevHrs += act;
                    credsUsed += act;
                }
            }
            item.TotalCredits = credsUsed
        });
        designTasks.forEach((item) => {
            let est = item.EstimatedHours;
            let act = item.ActualHours;
            item.TotalCredits = 0;
            let credsUsed;
            let reqDate = new Date(item.ReqDate);
            if (Object.keys(item).includes('MonthlyCredits')) {
                let creds = item.MonthlyCredits;
                if (creds.hasOwnProperty(formattedDate)) {
                    totDesignHrs += creds[formattedDate];
                    credsUsed += creds[formattedDate];
                }
            } else if (reqDate.getMonth() === date.getMonth() && reqDate.getFullYear() === date.getFullYear()) {
                if (act === undefined || act === null || act === 0 || !act) {
                    if (est === undefined || est === null || est === 0 || !est) {
                        totDesignHrs += 0;
                        credsUsed += 0;
                    } else {
                        totDesignHrs += est;
                        credsUsed += est
                    }
                } else {
                    totDesignHrs += act;
                    credsUsed += act;
                }
            }
            item.TotalCredits = credsUsed
        });
        salesTasks.forEach((item) => {
            let est = item.EstimatedHours;
            let act = item.ActualHours;
            item.TotalCredits = 0;
            let credsUsed;
            let reqDate = new Date(item.ReqDate);
            if (Object.keys(item).includes('MonthlyCredits')) {
                let creds = item.MonthlyCredits;
                if (creds.hasOwnProperty(formattedDate)) {
                    totSalesHrs += creds[formattedDate];
                    credsUsed += creds[formattedDate];
                }
            } else if (reqDate.getMonth() === date.getMonth() && reqDate.getFullYear() === date.getFullYear()) {
                if (act === undefined || act === null || act === 0 || !act) {
                    if (est === undefined || est === null || est === 0 || !est) {
                        totSalesHrs += 0;
                        credsUsed += 0;
                    } else {
                        totSalesHrs += est;
                        credsUsed += est
                    }
                } else {
                    totSalesHrs += act;
                    credsUsed += act;
                }
            }
            item.TotalCredits = credsUsed
        });
        let Credits = {
            Sales: (currentMonthCredits?.Departments?.Sales ?? 0) - totSalesHrs || 0,
            Dev: (currentMonthCredits?.Departments?.Dev ?? 0) - totDevHrs || 0,
            Design: (currentMonthCredits?.Departments?.Design ?? 0) - totDesignHrs
        }
        setTasks(tasks);
        setCredits(Credits);
        store.setLoading(false);
        forceUpdate();
    }
    function selectTab(e) {
        setTabSelected(e.currentTarget.id)
        forceUpdate();
    };
    function addNewTaskClicked() {
        setAddNewTask(true);
        forceUpdate();
    };
    function closeAddNewTask() {
        setAddNewTask(false);
        setShowTaskInfo(false);
        getTasks();
        forceUpdate()
        setJobRequest({
            ActualDueDate: new Date(),
            Title: "",
            Description: "",
            FeedBack: '',
            Department: "",
            Archived: false,
            DesignSpec: {
                designElements: "",
                collaterals: "",
                category: "",
                listDeliverables: "",
                DocLink: '',
                Platform: '',
                Sizes: '',
                CopyIfRequired: '',
                Attachments: ''
            },
            DevSpec: {
                Platform: "",
                Type: "",
                Outcomes: "",
                Figma_Available: ""
            },
            ReqDate: new Date(),
            DueDate: ''
        });
    };
    function jobRequestChange(e) {
        let job = JobRequest;
        job.ActualDueDate = null;
        job.FeedBack = "";
        if (e.currentTarget.id === "dueDate") {
            job.DueDate = e.target.value;
        } else {
            job[e.currentTarget.id] = e.target.value;
        }
        if (e.currentTarget.id === "designElements") {
            job.DesignSpec.designElements = e.target.value;
        }
        if (e.currentTarget.id === "Collaterals") {
            job.DesignSpec.collaterals = e.target.value;
        }
        if (e.currentTarget.id === "category") {
            JobRequest.DesignSpec.category = e.target.value;
        }
        if (e.currentTarget.id === "listDeliverables") {
            job.DesignSpec.listDeliverables = e.target.value;
        }
        if (e.currentTarget.id === "docLinks") {
            job.DesignSpec.DocLink = e.target.value;
        }
        if (e.currentTarget.id === "Size") {
            job.DesignSpec.Sizes = e.target.value;
        }
        if (e.currentTarget.id === "CopyIfRequired") {
            job.DesignSpec.CopyIfRequired = e.target.value;
        }
        if (e.currentTarget.id === "Attachments") {
            job.DesignSpec.Attachments = e.target.value;
        }
        if (e.currentTarget.id === "Platform") {
            job.DesignSpec.Platform = e.target.value;
        }
        if (e.currentTarget.id === "Platform") {
            job.DevSpec.Platform = e.target.value;
        }
        if (e.currentTarget.id === "Type") {
            job.DevSpec.Type = e.target.value;
        }
        if (e.currentTarget.id === "Outcomes") {
            job.DevSpec.Outcomes = e.target.value;
        }
        if (e.currentTarget.id === "Figma_Available") {
            job.DevSpec.Figma_Available = e.target.value;
        }
        if (e.currentTarget.id === "Figma_Available" || e.currentTarget.id === "Figma_NotAvailable") {
            job.DevSpec.Figma_Available = e.target.value;
        }
        if (e.currentTarget.id === "dueDate") {
            job.DueDate = e.target.value;
        }
        setJobRequest(job);
        forceUpdate();
    };
    const handleHoverStart = () => {
        setIsHovered(true);
        forceUpdate();
    };
    const handleHoverEnd = () => {
        setIsHovered(false);
        forceUpdate();
    };
    function departmentChanged(e) {
        let task = JobRequest
        task.Department = e.currentTarget.id;
        setJobRequest(task);
        forceUpdate();
    };
    function devPlatformChanged(e) {
        let task = JobRequest
        if (e.currentTarget.id === 'Other') {
            task.DevSpec.Platform = e.currentTarget.id;
            task.DevSpec.Type = 'Other';
        } else {
            task.DevSpec.Platform = e.currentTarget.id;
            task.DevSpec.Type = '';
        }
        setBackground('');
        setJobRequest(task);
        forceUpdate();
    };
    function devFigmaChanged(e) {
        let task = JobRequest
        task.DevSpec.Figma_Available = e.currentTarget.id;
        setJobRequest(task);
        forceUpdate();
    };
    function designCategoryChanged(e) {
        let task = JobRequest
        setBackground('');
        task.DesignSpec.category = e.currentTarget.id;
        task.DesignSpec.collaterals = '';
        setJobRequest(task);
        forceUpdate();
    };
    function designPlatformChanged(e) {
        let task = JobRequest
        task.DesignSpec.Platform = e.currentTarget.id;
        setJobRequest(task);
        forceUpdate();
    };
    function collateralChanged(e, subCategory) {
        let task = JobRequest;
        setBackground(subCategory);
        if (task.DesignSpec.Platform !== e.currentTarget.id) {
            task.DesignSpec.category = e.currentTarget.id;
            task.DesignSpec.collaterals = subCategory;
        }
    };
    function devCollateralChanged(e, subCategory) {
        let task = JobRequest;
        setBackground(subCategory);
        if (task.DevSpec.category !== e.currentTarget.id) {
            task.DevSpec.collaterals = e.currentTarget.id;
            task.DevSpec.Type = subCategory
        }
    };
    const submitTask = async () => {
        let task = JobRequest;
        // if (task.Department === 'Dev') {
        //     if (task.Description === '') {
        //         window.alert('Please fill in all required fields for the Development task.');
        //         return
        //     }
        //     if (task.DevSpec.Platform === '') {
        //         window.alert('Please fill in all required fields for the Development task.');
        //         return
        //     }
        //     if (task.DevSpec.Type === '') {
        //         window.alert('Please fill in all required fields for the Development task.');
        //         return
        //     }
        //     if (task.DevSpec.Outcomes === '') {
        //         window.alert('Please fill in all required fields for the Development task.');
        //         return
        //     }
        // } else if (task.Department === 'Design') {
        //     if (task.Description === '') {
        //         window.alert('Please fill in all required fields for the Design task.');
        //         return
        //     }
        //     if (task.DesignSpec.category === '') {
        //         window.alert('Please fill in all required fields for the Design task.');
        //         return
        //     }
        //     if (task.DesignSpec.collaterals === '') {
        //         window.alert('Please fill in all required fields for the Design task.');
        //         return
        //     }
        //     if (task.DesignSpec.Platform === '') {
        //         window.alert('Please fill in all required fields for the Design task.');
        //         return
        //     }
        //     if (task.DesignSpec.CopyIfRequired === '') {
        //         window.alert('Please fill in all required fields for the Design task.');
        //         return
        //     }
        // }
        task.Status = 'requested';
        task.ClientName = store.user.FirstName;
        task.Client = store.user.Company;
        task.ClientEmail = store.user.Email;
        store.setLoading(true);
        let response = await store.createJob(task);
        if (response === true) {
            window.alert('Task has been submitted.');
            store.setLoading(false);
            setAddNewTask(false);
        } else {
            window.alert('Please try again later.');
            store.setLoading(false);
        }
        setJobRequest({
            ActualDueDate: null,
            Title: "",
            Description: "",
            FeedBack: '',
            Department: "",
            Archived: false,
            Status: 'requested',
            Client: store.user.Company,
            ClientEmail: store.user.Email,
            DesignSpec: {
                designElements: "",
                collaterals: "",
                category: "",
                listDeliverables: "",
                DocLink: '',
                Platform: '',
                Sizes: '',
                CopyIfRequired: '',
                Attachments: ''
            },
            ClientName: '',
            DevSpec: {
                Platform: "",
                Type: "",
                Outcomes: "",
                Figma_Available: ""
            },
            ReqDate: new Date(),
            DueDate: ''
        });
        getTasks()
        forceUpdate();
    }
    function viewTaskInfo(e, index) {
        let task = Tasks[index];
        let totalCresd = 0;
        if (task.hasOwnProperty('MonthlyCredits')) {
            let data = task.MonthlyCredits
            totalCresd = Object.values(data).reduce((acc, value) => acc + value, 0);
        } else {
            totalCresd = task.EstimatedCredits || 0;
        }
        setSelectedTaskCreds(totalCresd);
        setSelectedTask(task);
        setShowTaskInfo(true);
        forceUpdate();
    };
    const handleAccept = (e) => {
        let task = SelectedTask;
        task.Status = e.currentTarget.id;
        store.setLoading(true);
        let updateTask = store.clientAcceptCreds(task);
        if (updateTask === 'error') {
            window.alert('There was an error updating this job.');
            store.setLoading(false);
        } else {
            setSelectedTask({});
            window.alert(`Credits Accepted successfully.`);
            setShowTaskInfo(false);
            store.setLoading(false);
        }
        getTasks()
        setAddNewTask(false);
        forceUpdate();
    };
    const handleAbandon = (e) => {
        let task = SelectedTask;
        task.Status = 'Declined peacefully.';
        task.Archived = true;
        store.setLoading(true);
        let updateTask = store.updateTaskById(task);
        if (updateTask === 'error') {
            window.alert('There was an error updating this job.');
            store.setLoading(false);
        } else {
            setSelectedTask({});
            window.alert(`Task Abandoned successfully.`);
            setShowTaskInfo(false);
            store.setLoading(false);
        }
        getTasks()
        setAddNewTask(false);
        forceUpdate();
    };
    const handleReject = (e) => {
        let task = SelectedTask;
        task.Status = e.currentTarget.id;
        store.setLoading(true);
        let updateTask = store.clientDeclineCreds(task);
        if (updateTask === 'error') {
            window.alert('There was an error updating this job.');
            store.setLoading(false);
        } else {
            setSelectedTask({});
            window.alert(`Estimated Credits rejected succefully.`);
            setShowTaskInfo(false);
            store.setLoading(false);
        }
        getTasks()
        setAddNewTask(false);
        forceUpdate();
    };
    const handleTitleFilterComps = () => {
        let tasks = Tasks;
        if (TitleFilter === 'Z-A') {
            setTitleFilter('A-Z');
            tasks.sort((a, b) => a.Title.localeCompare(b.Title));
        } else {
            setTitleFilter('Z-A');
            tasks.sort((a, b) => b.Title.localeCompare(a.Title));
        }
        setTasks(tasks);
        forceUpdate();
    };
    const resubmitTask = (e) => {
        let task = SelectedTask;
        task.Status = 'requested';
        if (e.currentTarget.id === 'Title') {
            task.Title = e.target.value;
        }
        if (e.currentTarget.id === 'dueDate') {
            task.DueDate = e.target.value;
        }
        if (e.currentTarget.id === 'Description') {
            task.Description = e.target.value;
        }
        if (e.currentTarget.id === 'Outcomes') {
            task.DevSpec.Outcomes = e.target.value;
        }
        if (e.currentTarget.id === 'Size') {
            task.DesignSpec.Sizes = e.target.value;
        }
        if (e.currentTarget.id === 'CopyIfRequired') {
            task.DesignSpec.CopyIfRequired = e.target.value;
        }
        if (e.currentTarget.id === 'Attachments') {
            task.DesignSpec.Attachments = e.target.value;
        }
        forceUpdate();
    }
    const reSubmitDepartment = (e) => {
        let task = SelectedTask;
        task.Department = e.currentTarget.id;
        setSelectedTask(task);
        forceUpdate();
    }
    function reSubmitDevPlatformChanged(e) {
        let task = SelectedTask
        task.DevSpec.Platform = e.currentTarget.id;
        setBackground('');
        setSelectedTask(task);
        forceUpdate();
    };
    function reSubMitDevFigmaChanged(e) {
        let task = SelectedTask;
        task.DevSpec.Figma_Available = e.currentTarget.id;
        setSelectedTask(task);
        forceUpdate();
    };
    function reSubmitDesignCategoryChanged(e) {
        let task = SelectedTask
        setBackground('');
        task.DesignSpec.category = e.currentTarget.id;
        task.DesignSpec.collaterals = '';
        setSelectedTask(task);
        forceUpdate();
    };
    function resubmitDesignPlatformChanged(e) {
        let task = SelectedTask
        task.DesignSpec.Platform = e.currentTarget.id;
        setSelectedTask(task);
        forceUpdate();
    };
    const reSubmitTask = async () => {
        let task = SelectedTask;
        if (task.Description === '') {
            window.alert('Description');
            return
        }
        if (task.ActualDueDate === '' || task.ActualDueDate === '') {
            window.alert('Requested Due Date.');
            return
        }
        if (task.Department === '') {
            window.alert('Department');
            return
        }
        if (task.Department === 'Dev' && SelectedTask.Description === '' && SelectedTask.DevSpec.Platform === '' && SelectedTask.DevSpec.Type === '' && SelectedTask.DevSpec.Outcomes === '') {
            window.alert('Dev Specs');
            return
        }
        task.Status = 'requested';
        task.ClientName = store.user.FirstName;
        store.setLoading(true);
        let response = await store.updateTaskById(task);
        if (response) {
            window.alert('Task Re-Submitted successfully.');
            store.setLoading(false);
            setAddNewTask(false);
        } else {
            window.alert('Please try again later.');
            store.setLoading(false);
        }
        ShowTaskInfo(false);
        getTasks()
        setSelectedTask({});
        forceUpdate();
    }
    const taskNameResubmit = (e) => {
        let task = SelectedTask;
        if (e.currentTarget.id === 'Title') {
            task.Title = e.target.value;
        }
        if (e.currentTarget.id === 'dueDate') {
            task.DueDate = e.target.value;
        }
        if (e.currentTarget.id === 'Description') {
            task.Description = e.target.value;
        }
        if (e.currentTarget.id === 'Outcomes') {
            task.DevSpec.Outcomes = e.target.value;
        }

        setSelectedTask(task);
        forceUpdate();
    }
    if (mobile()) {
        return (
            <View >
            </View>
        );
    } else {
        return (
            <div className='alkemiaBriefingHomepage'>
                <div className='investeeHeader'>
                    <button className='addNewTaskBtn' onClick={addNewTaskClicked}><b>New Task</b></button>
                    <div className='investeeCreditsSection'>
                        <div className='remainingCreditsCard'><p>Dev</p><div className='remainingCreditsValue'><b>{Credits.Dev || 0}</b></div></div>
                        <div className='remainingCreditsCard'><p>Design</p><div className='remainingCreditsValue'><b>{Credits.Design || 0}</b></div></div>
                    </div>
                </div>
                <div className='investeeTabsSection'>
                    <div className={`investeeStatusCard ${TabSelected === 'requested' ? 'selectedInvesteeStatusCard' : ''}`} style={{ marginLeft: '0%' }} onClick={selectTab} id='requested'><p>Requested</p><b>{(Tasks.filter((t) => t.Status === 'requested' && !t.Archived)).length}</b></div>
                    <div className={`investeeStatusCard ${TabSelected === 'pending' ? 'selectedInvesteeStatusCard' : ''}`} onClick={selectTab} id='pending'><p>Pending</p><b>{(Tasks.filter((t) => t.Status === 'pending' && !t.Archived)).length}</b></div>
                    <div className={`investeeStatusCard ${TabSelected === 'inProgress' ? 'selectedInvesteeStatusCard' : ''}`} onClick={selectTab} id='inProgress'><p>In Progress</p><b>{(Tasks.filter((t) => t.Status === 'inProgress' && !t.Archived)).length}</b></div>
                    <div className={`investeeStatusCard ${TabSelected === 'Completed' ? 'selectedInvesteeStatusCard' : ''}`} onClick={selectTab} id='Completed'><p>Completed</p><b>{(Tasks.filter((t) => t.Status === 'Completed' || t.Status === 'Complete' && !t.Archived)).length}</b></div>
                    <div className={`investeeStatusCard ${TabSelected === 'Declined peacefully.' ? 'selectedInvesteeStatusCard' : ''}`} onClick={selectTab} id='Declined peacefully.'><p>Declined</p><b>{(Tasks.filter((t) => t.Status === 'Declined peacefully.' && !t.Archived)).length}</b></div>
                </div>
                <div className='investeeTaskHeaders'>
                    <p className='taskTitleHeader' onClick={handleTitleFilterComps}><img src={TitleFilter === 'A-Z' ? FilterDown : Filter} onClick={handleTitleFilterComps} />Title</p>
                    <p className='taskDescHeader'>Description</p>
                    <p className='taskCredsHeader'>Credits</p>
                </div>
                <div className='investeeAvailableTasks'>
                    {Tasks.map((item, index) => {
                        if (TabSelected === 'Completed' && item.Status !== 'Completed' && item.Status !== 'Complete') {
                            return null;
                        } else if (TabSelected !== 'Completed' && item.Status !== TabSelected) {
                            return null;
                        }
                        let desc = item.Description.slice(0, 30) + '...';
                        let totalCresd
                        if (item.hasOwnProperty('MonthlyCredits')) {
                            let data = item.MonthlyCredits
                            totalCresd = Object.values(data).reduce((acc, value) => acc + value, 0);
                        } else {
                            totalCresd = item.EstimatedCredits || 0;
                        }
                        return (
                            <div className='availableTasks' key={index} onClick={(e) => viewTaskInfo(e, index)}>
                                <p className='taskTitle'>{item.Title.length > 17 ? item.Title.substring(0, 17) + '...' : item.Title}</p>
                                <p className='taskDescription'>{desc || ''}</p>
                                <p className='taskUsedCreds'><b>{totalCresd || 0}</b></p>
                            </div>
                        );
                    })}
                </div>
                {(AddNewTask) ?
                    <Card className='AddNewTAskCard'>
                        <div className='topBarSection'>
                            <img src={CloseCard} onClick={closeAddNewTask} />
                        </div>
                        <div className='taskNameSection'>
                            <Textbox className='taskNameInput' placeholder='Task Name *' id='Title' onChange={jobRequestChange} inputStyle={{ marginTop: '1%' }} style={{ marginTop: 'unset' }} />
                            <CustomSelect className='departmentInput' onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd} Active={isHovered} id='Departments' value={JobRequest.Department} placeholder='Department *' style={{ marginTop: 'unset' }}>
                                <div onClick={departmentChanged} value='Dev' id='Dev' className={`${(JobRequest.Department === 'Dev') ? 'selectActive' : 'customOptions'}`} >Dev</div>
                                <div onClick={departmentChanged} value='Design' id='Design' className={`${(JobRequest.Department === 'Design') ? 'selectActive' : 'customOptions'}`}>Design</div>
                            </CustomSelect>
                            <Textbox className='dateSection' placeholder='Requested Due Date *' type='date' id="dueDate" style={{ width: '35%', marginTop: 'unset' }} inputStyle={{ marginTop: '3%', display: 'flex', width: 'unset' }} onChange={jobRequestChange} />
                        </div>
                        <hr className='hr' />
                        <div className='collateralsSection'>
                            <div className='departmentCategoriesCard'>
                                {(JobRequest.Department === 'Dev') ?
                                    <>
                                        <div className='taskCategorySection'>
                                            <CustomSelect className='plartformSelects' placeholder='Platform *' onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd} Active={true} id="Platform" value={JobRequest.DevSpec.Platform} onChange={jobRequestChange}>
                                                <div onClick={devPlatformChanged} id="App" className={`${(JobRequest.DevSpec.Platform === 'App') ? 'selectActive' : 'customOptions'}`}>App</div>
                                                <div onClick={devPlatformChanged} id="Website" className={`${(JobRequest.DevSpec.Platform === 'Website') ? 'selectActive' : 'customOptions'}`}>Website</div>
                                                <div onClick={devPlatformChanged} id="Other" className={`${(JobRequest.DevSpec.Platform === 'Other') ? 'selectActive' : 'customOptions'}`}>Other</div>
                                            </CustomSelect>
                                            <CustomSelect className='plartformSelects' placeholder='Design Available?*' id='Figma_Available' onChange={jobRequestChange} value={JobRequest.DevSpec.Figma_Available}>
                                                <div id='Yes' onClick={devFigmaChanged} className={`${(JobRequest.DevSpec.Figma_Available === 'Yes') ? 'selectActive' : 'customOptions'}`}>Yes</div>
                                                <div id='No' onClick={devFigmaChanged} className={`${(JobRequest.DevSpec.Figma_Available === 'No') ? 'selectActive' : 'customOptions'}`}>No</div>
                                            </CustomSelect>
                                        </div>
                                        <div className='taskDescSection'>
                                            <ResizableTextarea className='descriptionTextB' placeholder='Description *' id='Description' onChange={jobRequestChange} />
                                            <ResizableTextarea className='projectObjectiveText' placeholder='What should this project achieve? *' id="Outcomes" onChange={jobRequestChange} />
                                            {(JobRequest.Title !== '' && JobRequest.ActualDueDate !== '' && JobRequest.Description !== '' && JobRequest.DevSpec.Platform !== '' && JobRequest.DevSpec.Type !== '' && JobRequest.DevSpec.Figma_Available !== '' && JobRequest.DevSpec.CopyIfRequired !== '' && JobRequest.DevSpec.Outcomes !== '') ? (
                                                <button className='requirementsMetBtn' onClick={submitTask}><b>Submit</b></button>
                                            ) : (
                                                <button className='requirementsMetBtn' onClick={submitTask}><b>Submit</b></button>
                                            )}
                                        </div>
                                    </> : (JobRequest.Department === 'Design') ?
                                        <>
                                            <div className='taskCategorySection'>
                                                <CustomSelect className='plartformSelects' placeholder='Category *' id='category' onChange={jobRequestChange} value={JobRequest.DesignSpec.category}>
                                                    <div onClick={designCategoryChanged} className={`${(JobRequest.DesignSpec.category === 'StillGraphics') ? 'selectActive' : 'customOptions'}`} id="StillGraphics">Still Graphics</div>
                                                    <div onClick={designCategoryChanged} className={`${(JobRequest.DesignSpec.category === 'WebDesign') ? 'selectActive' : 'customOptions'}`} id="WebDesign">Web Design</div>
                                                    <div onClick={designCategoryChanged} className={`${(JobRequest.DesignSpec.category === 'Video/Animation') ? 'selectActive' : 'customOptions'}`} id="Video/Animation">Video/ Animation</div>
                                                    <div onClick={designCategoryChanged} className={`${(JobRequest.DesignSpec.category === 'Photo') ? 'selectActive' : 'customOptions'}`} id="Photo">Photo</div>
                                                    <div onClick={designCategoryChanged} className={`${(JobRequest.DesignSpec.category === 'Written') ? 'selectActive' : 'customOptions'}`} id="Written">Written</div>
                                                </CustomSelect>
                                                <CustomSelect className='plartformSelects' placeholder='Platform *' id='Platform' onChange={jobRequestChange} value={JobRequest.DesignSpec.Platform}>
                                                    <div onClick={designPlatformChanged} className={`${(JobRequest.DesignSpec.Platform === 'SocialMediaPost') ? 'selectActive' : 'customOptions'}`} id="SocialMediaPost">Social Media Post</div>
                                                    <div onClick={designPlatformChanged} className={`${(JobRequest.DesignSpec.Platform === 'SocialMediaStory') ? 'selectActive' : 'customOptions'}`} id="SocialMediaStory">Social Media Story</div>
                                                    <div onClick={designPlatformChanged} className={`${(JobRequest.DesignSpec.Platform === 'Website') ? 'selectActive' : 'customOptions'}`} id="Website">Website</div>
                                                    <div onClick={designPlatformChanged} className={`${(JobRequest.DesignSpec.Platform === 'DigitalPDF') ? 'selectActive' : 'customOptions'}`} id="DigitalPDF">Digital PDF</div>
                                                    <div onClick={designPlatformChanged} className={`${(JobRequest.DesignSpec.Platform === 'PrintPDF') ? 'selectActive' : 'customOptions'}`} id="PrintPDF">Print PDF</div>
                                                    <div onClick={designPlatformChanged} className={`${(JobRequest.DesignSpec.Platform === 'Youtube') ? 'selectActive' : 'customOptions'}`} id="Youtube">Youtube</div>
                                                    <div onClick={designPlatformChanged} className={`${(JobRequest.DesignSpec.Platform === 'GoogleCampaigns') ? 'selectActive' : 'customOptions'}`} id="GoogleCampaigns">Google Campaigns</div>
                                                    <div onClick={designPlatformChanged} className={`${(JobRequest.DesignSpec.Platform === 'LiveEvents') ? 'selectActive' : 'customOptions'}`} id="LiveEvents">LiveEvents</div>
                                                    <div onClick={designPlatformChanged} className={`${(JobRequest.DesignSpec.Platform === 'Other') ? 'selectActive' : 'customOptions'}`} id="Other">Other</div>
                                                </CustomSelect>
                                            </div>
                                            <div className='taskDescSection'>
                                                <ResizableTextarea className='descriptionTextB' placeholder='Description *' id='Description' onChange={jobRequestChange} />
                                                <ResizableTextarea className='descriptionTextB' placeholder='Size/ Dimensions *' id='Size' onChange={jobRequestChange} />
                                                <ResizableTextarea className='projectObjectiveText' placeholder='Text/ Copy *' id='CopyIfRequired' onChange={jobRequestChange} />
                                                <ResizableTextarea className='descriptionTextB' placeholder='Attachments' id='Attachments' onChange={jobRequestChange} />
                                                {(JobRequest.Title !== '' && JobRequest.ActualDueDate !== '' && JobRequest.Description !== '' && JobRequest.DesignSpec.category !== '' && JobRequest.DesignSpec.collaterals !== '' && JobRequest.DesignSpec.Platform !== '' && JobRequest.DesignSpec.CopyIfRequired !== '') ? (
                                                    <button className='requirementsMetBtn' onClick={submitTask}><b>Submit</b></button>
                                                ) : (
                                                    <button className='requirementsMetBtn' onClick={submitTask}><b>Submit</b></button>
                                                )}
                                            </div>
                                        </>
                                        : null}
                            </div>
                            <div className='actionSectionCard'>
                                {(JobRequest.Department !== '') ?
                                    <>
                                        <div className='categoryHeader'>
                                            <p>Design Category Details</p>
                                        </div>
                                        <div className='collateralDet'>
                                            {Categories.map((item, index) => {
                                                if (item.Department !== JobRequest.Department) {
                                                    return
                                                }
                                                if (item.Department === 'Design') {
                                                    if (JobRequest.DesignSpec.category !== '' && item.Category !== JobRequest.DesignSpec.category) {
                                                        return
                                                    } else {
                                                        return (
                                                            <Card key={index} className='colateralCards' id={item.Category.replace(/\s+/g, '')} onClick={(e) => collateralChanged(e, item.subCategory)} style={{ backgroundColor: item.subCategory === Background ? '#EFB86F' : '#EAEAEA', cursor: 'pointer' }} >
                                                                <h3 className='header'>{item.subCategory}</h3>
                                                                <img src={item.Image} alt='' />
                                                                <p>{item.Description}</p>
                                                            </Card>
                                                        )
                                                    }
                                                } else if (item.Department === 'Dev') {
                                                    if (JobRequest.DevSpec.Platform !== '' && item.Category !== JobRequest.DevSpec.Platform) {
                                                        return
                                                    } else {
                                                        return (
                                                            <Card key={index} className='colateralCards' id={item.Category.replace(/\s+/g, '')} onClick={(e) => devCollateralChanged(e, item.subCategory)} style={{ backgroundColor: item.subCategory === Background ? '#EFB86F' : '#EAEAEA' }}>
                                                                <h3 className='header'>{item.subCategory}</h3>
                                                                <img src={item.Image} alt='' />
                                                                <p>{item.Description}</p>
                                                            </Card>
                                                        )
                                                    }
                                                }

                                            })}
                                        </div>
                                    </>
                                    : null}
                            </div>
                        </div>
                    </Card> : null}

                {(ShowTaskInfo && SelectedTask.Status !== 'Declined peacefully.') ?
                    <Card className='viewTask'>
                        <div className='headerSection'>
                            <h3 className='taskNameTxt' onClick={closeAddNewTask}>{SelectedTask.Title.length > 17 ? SelectedTask.Title.substring(0, 17) + '...' : SelectedTask.Title}</h3>
                            <img className='closeTaskImg' src={CloseCard} onClick={closeAddNewTask} />
                        </div>
                        <div className='TaskInfoSection'>
                            <Textbox className='viewedTaskInputs' placeholder='Department' value={SelectedTask.Department} readOnly />
                            <Textbox className='viewedTaskInputs' placeholder='Requested Due Date' value={(new Date(SelectedTask.DueDate)).toLocaleDateString()} readOnly />
                            <Textbox className='viewedTaskInputs' placeholder='Category' value={SelectedTask.Department === 'Dev' ? SelectedTask.DevSpec.Type : SelectedTask.DesignSpec.category} readOnly />
                            <Textbox className='viewedTaskInputs' placeholder='Platform' value={SelectedTask.Department === 'Dev' ? SelectedTask.DevSpec.Platform : SelectedTask.DesignSpec.Platform} style={{ marginRight: 'unset' }} readOnly />
                        </div>
                        <hr className='hr' />
                        {(SelectedTask.Department === 'Design') ? (
                            <div className='descriptionBar'>
                                <p className='descriptionHeader'>Description</p>
                                <p className='descriptionValue'>{SelectedTask.Description}</p>
                                <p className='descriptionHeader'>Size/ Dimensions</p>
                                <p className='descriptionValue'>{SelectedTask.DesignSpec.Sizes}</p>
                                <p className='descriptionHeader'>Text/ Copy</p>
                                <p className='descriptionValue'>{SelectedTask.DesignSpec.Figma_Available}</p>
                                <p className='descriptionHeader'>Collateral</p>
                                <p className='descriptionValue'>{SelectedTask.DesignSpec.CopyIfRequired}</p>
                                <p className='descriptionHeader'>Attachments</p>
                                <p className='descriptionValue'>{SelectedTask.DesignSpec.Attachments}</p>
                            </div>
                        ) : (SelectedTask.Department === 'Dev') ? (
                            <div className='descriptionBar'>
                                <p className='descriptionHeader'>Description</p>
                                <p className='descriptionValue'>{SelectedTask.Description}</p>
                                <p className='descriptionHeader'>What should this project achieve?</p>
                                <p className='descriptionValue'>{SelectedTask.DevSpec.Outcomes}</p>
                                <p className='descriptionHeader'>Is there a design Available?</p>
                                <p className='descriptionValue'>{SelectedTask.DevSpec.Figma_Available}</p>
                            </div>
                        ) : null}
                        <div className='viewTAskCredsCreditsSection'>
                            <div className='viewEstimatedOrassignedCeds'>
                                {(SelectedTask.Status === 'Complete' || SelectedTask.Status === 'Completed') ?
                                    <Card className='estCredsCard'>
                                        <p>Credits</p>
                                        <div className='remCreditsCard'><b>{SelectedTaskCreds || 0}</b></div>
                                    </Card>
                                    :
                                    <Card className='estCredsCard'>
                                        <p>Estimated Credits</p>
                                        <div className='remCreditsCard'><b>{SelectedTaskCreds || 0}</b></div>
                                    </Card>
                                }
                                <Card className='estCredsCard'><p>Delivery Date</p><div className='deliveryDate'><b>{(new Date(SelectedTask.ActualDueDate)).toLocaleDateString()}</b></div></Card>
                            </div>
                            <div className='acceptOrRejctSection'>
                                {(SelectedTask.Status === 'pending') ?
                                    <div className='investeeDecisionSection'>
                                        <button className='acceptEstimatedCredsBtn' id='inProgress' onClick={handleAccept}><p>Accept</p></button>
                                        <button className='rejectEstimatedCredsBtn' id='Declined peacefully.' onClick={handleReject}><p>Reject</p></button>
                                    </div>
                                    : (SelectedTask.Status === 'requested' || SelectedTask.Status === 'Declined peacefully.') ?
                                        <p style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleAbandon}>Abandon this task.</p>
                                        : null}
                            </div>
                        </div>
                    </Card> : null}
                {(ShowTaskInfo && SelectedTask.Status === 'Declined peacefully.') ?
                    <Card className='AddNewTAskCard'>
                        <div className='topBarSection'>
                            <img src={CloseCard} onClick={closeAddNewTask} />
                        </div>
                        <div className='taskNameSection'>
                            <Textbox className='taskNameInput' placeholder='Task Name *' id='Title' onChange={taskNameResubmit} inputStyle={{ marginTop: '1%' }} style={{ marginTop: 'unset' }} value={SelectedTask.Title} />
                            <CustomSelect className='departmentInput' onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd} Active={isHovered} id='Departments' value={JobRequest.Department} placeholder='Department *' style={{ marginTop: 'unset' }}>
                                <div onChange={reSubmitDepartment} value='Dev' id='Dev' className={`${(SelectedTask.Department === 'Dev') ? 'selectActive' : 'customOptions'}`} >Dev</div>
                                <div onChange={reSubmitDepartment} value='Design' id='Design' className={`${(SelectedTask.Department === 'Design') ? 'selectActive' : 'customOptions'}`}>Design</div>
                            </CustomSelect>
                            <Textbox className='dateSection' placeholder='Requested Due Date *' type='date' id="dueDate" style={{ width: '35%', height: '40px', marginTop: 'unset' }} inputStyle={{ marginTop: '3%', display: 'flex', width: 'unset' }} onChange={taskNameResubmit} value={SelectedTask.DueDate} />
                        </div>
                        <hr className='hr' />
                        <div className='collateralsSection'>
                            <div className='departmentCategoriesCard'>
                                {(SelectedTask.Department === 'Dev') ?
                                    <>
                                        <div className='taskCategorySection'>
                                            <CustomSelect className='plartformSelects' placeholder='Platform *' onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd} Active={true} id="Platform" value={SelectedTask.DevSpec.Platform} onChange={resubmitTask}>
                                                <div onClick={reSubmitDevPlatformChanged} id="App" className={`${(SelectedTask.DevSpec.Platform === 'App') ? 'selectActive' : 'customOptions'}`}>App</div>
                                                <div onClick={reSubmitDevPlatformChanged} id="Website" className={`${(SelectedTask.DevSpec.Platform === 'Website') ? 'selectActive' : 'customOptions'}`}>Website</div>
                                                <div onClick={reSubmitDevPlatformChanged} id="Other" className={`${(SelectedTask.DevSpec.Platform === 'Other') ? 'selectActive' : 'customOptions'}`}>Other</div>
                                            </CustomSelect>
                                            <CustomSelect className='plartformSelects' placeholder='Design Available?*' id='Figma_Available' onChange={resubmitTask} value={SelectedTask.DevSpec.Figma_Available}>
                                                <div id='Yes' onClick={reSubMitDevFigmaChanged} className={`${(SelectedTask.DevSpec.Figma_Available === 'Yes') ? 'selectActive' : 'customOptions'}`}>Yes</div>
                                                <div id='No' onClick={reSubMitDevFigmaChanged} className={`${(SelectedTask.DevSpec.Figma_Available === 'No') ? 'selectActive' : 'customOptions'}`}>No</div>
                                            </CustomSelect>
                                        </div>
                                        <div className='taskDescSection'>
                                            <Textbox className='descriptionTextB' placeholder='Description *' id='Description' onChange={taskNameResubmit} value={SelectedTask.Description} />
                                            <Textarea className='projectObjectiveText' placeholder='What should this project achieve? *' id="Outcomes" onChange={taskNameResubmit} value={SelectedTask.DevSpec.Outcomes} />
                                        </div>
                                        <div className='submitTaskSection'>
                                            {(SelectedTask.Title !== '' && SelectedTask.ActualDueDate !== '' || SelectedTask.ActualDueDate !== '' && SelectedTask.Description !== '' && SelectedTask.DevSpec.Platform !== '', SelectedTask.DevSpec.Type !== '', SelectedTask.DevSpec.Outcomes !== '') ? (
                                                <button className='requirementsMetBtn' onClick={reSubmitTask}><b>Re-Submit</b></button>
                                            ) : (
                                                <button className='submitTask'><b>Re-Submit</b></button>
                                            )}
                                        </div>
                                    </> : (SelectedTask.Department === 'Design') ?
                                        <>
                                            <div className='taskCategorySection'>
                                                <CustomSelect className='plartformSelects' placeholder='Category *' id='category' onChange={resubmitTask} value={SelectedTask.DesignSpec.category}>
                                                    <div onClick={reSubmitDesignCategoryChanged} className={`${(SelectedTask.DesignSpec.category === 'StillGraphics') ? 'selectActive' : 'customOptions'}`} id="StillGraphics">Still Graphics</div>
                                                    <div onClick={reSubmitDesignCategoryChanged} className={`${(SelectedTask.DesignSpec.category === 'WebDesign') ? 'selectActive' : 'customOptions'}`} id="WebDesign">Web Design</div>
                                                    <div onClick={reSubmitDesignCategoryChanged} className={`${(SelectedTask.DesignSpec.category === 'Video/Animation') ? 'selectActive' : 'customOptions'}`} id="Video/Animation">Video/ Animation</div>
                                                    <div onClick={reSubmitDesignCategoryChanged} className={`${(SelectedTask.DesignSpec.category === 'Photo') ? 'selectActive' : 'customOptions'}`} id="Photo">Photo</div>
                                                    <div onClick={reSubmitDesignCategoryChanged} className={`${(SelectedTask.DesignSpec.category === 'Written') ? 'selectActive' : 'customOptions'}`} id="Written">Written</div>
                                                </CustomSelect>
                                                <CustomSelect className='plartformSelects' placeholder='Platform *' id='Platform' onChange={resubmitTask} value={SelectedTask.DesignSpec.Platform}>
                                                    <div onClick={resubmitDesignPlatformChanged} className={`${(SelectedTask.DesignSpec.Platform === 'SocialMediaPost') ? 'selectActive' : 'customOptions'}`} id="SocialMediaPost">Social Media Post</div>
                                                    <div onClick={resubmitDesignPlatformChanged} className={`${(SelectedTask.DesignSpec.Platform === 'SocialMediaStory') ? 'selectActive' : 'customOptions'}`} id="SocialMediaStory">Social Media Story</div>
                                                    <div onClick={resubmitDesignPlatformChanged} className={`${(SelectedTask.DesignSpec.Platform === 'Website') ? 'selectActive' : 'customOptions'}`} id="Website">Website</div>
                                                    <div onClick={resubmitDesignPlatformChanged} className={`${(SelectedTask.DesignSpec.Platform === 'DigitalPDF') ? 'selectActive' : 'customOptions'}`} id="DigitalPDF">Digital PDF</div>
                                                    <div onClick={resubmitDesignPlatformChanged} className={`${(SelectedTask.DesignSpec.Platform === 'PrintPDF') ? 'selectActive' : 'customOptions'}`} id="PrintPDF">Print PDF</div>
                                                    <div onClick={resubmitDesignPlatformChanged} className={`${(SelectedTask.DesignSpec.Platform === 'Youtube') ? 'selectActive' : 'customOptions'}`} id="Youtube">Youtube</div>
                                                    <div onClick={resubmitDesignPlatformChanged} className={`${(SelectedTask.DesignSpec.Platform === 'GoogleCampaigns') ? 'selectActive' : 'customOptions'}`} id="GoogleCampaigns">Google Campaigns</div>
                                                    <div onClick={resubmitDesignPlatformChanged} className={`${(SelectedTask.DesignSpec.Platform === 'LiveEvents') ? 'selectActive' : 'customOptions'}`} id="LiveEvents">LiveEvents</div>
                                                    <div onClick={resubmitDesignPlatformChanged} className={`${(SelectedTask.DesignSpec.Platform === 'Other') ? 'selectActive' : 'customOptions'}`} id="Other">Other</div>
                                                </CustomSelect>
                                            </div>
                                            <div className='taskDescSection'>
                                                <Textbox className='descriptionTextB' placeholder='Description *' id='Description' onChange={resubmitTask} />
                                                <Textbox className='descriptionTextB' placeholder='Size/ Dimensions *' id='Size' onChange={resubmitTask} />
                                                <Textarea className='projectObjectiveText' placeholder='Text/ Copy *' id='CopyIfRequired' onChange={resubmitTask} />
                                                <Textbox className='descriptionTextB' placeholder='Attachments' id='Attachments' onChange={resubmitTask} />
                                            </div>
                                            <div className='submitTaskSection'>
                                                {(SelectedTask.Title !== '' && SelectedTask.ActualDueDate !== '' || SelectedTask.ActualDueDate !== '' && SelectedTask.Description !== '' && SelectedTask.DesignSpec.category !== '' && SelectedTask.DesignSpec.collaterals !== '' && SelectedTask.DesignSpec.Platform !== '' && SelectedTask.DesignSpec.CopyIfRequired !== '') ? (
                                                    <button className='requirementsMetBtn' onClick={reSubmitTask}><b>Re-Submit</b></button>
                                                ) : (
                                                    <button className='submitTask'><b>Re-Submit</b></button>
                                                )}
                                            </div>
                                        </>
                                        : null}
                            </div>
                            <div className='actionSectionCard'>
                                {(SelectedTask.Department !== '') ?
                                    <>
                                        <div className='categoryHeader'>
                                            <p>Design Category Details</p>
                                        </div>
                                        <div className='collateralDet'>
                                            {Categories.map((item, index) => {
                                                if (item.Department !== SelectedTask.Department) {
                                                    return
                                                }
                                                if (item.Department === 'Design') {
                                                    if (SelectedTask.DesignSpec.category !== '' && item.Category !== SelectedTask.DesignSpec.category) {
                                                        return
                                                    } else {
                                                        return (
                                                            <Card key={index} className='colateralCards' id={item.Category.replace(/\s+/g, '')} onClick={(e) => collateralChanged(e, item.subCategory)} style={{ backgroundColor: item.subCategory === Background ? '#EFB86F' : '#EAEAEA' }}>
                                                                <h3 className='header'>{item.subCategory}</h3>
                                                                <img src={item.Image} alt='' />
                                                                <p>{item.Description}</p>
                                                            </Card>
                                                        )
                                                    }
                                                } else if (item.Department === 'Dev') {
                                                    if (SelectedTask.DevSpec.Platform !== '' && item.Category !== SelectedTask.DevSpec.Platform) {
                                                        return
                                                    } else {
                                                        return (
                                                            <Card key={index} className='colateralCards' id={item.Category.replace(/\s+/g, '')} onClick={(e) => devCollateralChanged(e, item.subCategory)} style={{ backgroundColor: item.subCategory === Background ? '#EFB86F' : '#EAEAEA' }}>
                                                                <h3 className='header'>{item.subCategory}</h3>
                                                                <img src={item.Image} alt='' />
                                                                <p>{item.Description}</p>
                                                            </Card>
                                                        )
                                                    }
                                                }

                                            })}
                                        </div>
                                    </>
                                    : null}
                            </div>
                        </div>
                    </Card> : null}
            </div>
        );
    }
});

export default AlkemiaBriefing;
